<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">
      <!-- Left Text-->
      <b-col
        lg="8"
        class="d-none d-lg-flex align-items-center p-5"
      >
        <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
          <b-img
            fluid
            :src="loginBackground"
            alt="Login Background"
          />
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Login-->
      <b-col
        lg="4"
        class="d-flex align-items-center auth-bg px-2 p-lg-5"
      >
        <b-col
          sm="8"
          md="6"
          lg="12"
          class="px-xl-2 mx-auto"
        >
          <b-card-title
            class="mb-1 font-weight-bold"
            title-tag="h2"
          >
            <span class="text-primary font-weight-bolder">Connect Land</span> 👋
          </b-card-title>
          <b-alert
            variant="primary"
            show
          >
            <div class="alert-body font-small-3">
              <p>
                Thông tin đăng nhập của bạn
              </p>
            </div>
            <feather-icon
              v-b-tooltip.hover.left="'Liên hệ Admin để lấy thông tin đăng nhập'"
              icon="HelpCircleIcon"
              size="18"
              class="position-absolute"
              style="top: 10px; right: 10px;"
            />
          </b-alert>

          <!-- form -->
          <b-form
            id="login-form"
            ref="login-form"
            class="auth-login-form mt-2"
            @submit.prevent="login"
          >
            <!-- email -->
            <dynamic-form
              :id="'login-form-email'"
              v-model="form.email"
              :type="'text'"
              :label="'Email'"
              required="required email"
              @update-value="val => form.email = val"
            />

            <!-- mật khẩu -->
            <dynamic-form
              :id="'login-form-password'"
              v-model="form.password"
              :type="'password'"
              :label="'Mật khẩu'"
              required="required password"
              @update-value="val => form.password = val"
            />
            <b-button
              type="submit"
              variant="primary"
              block
            >
              Đăng nhập
            </b-button>

            <!--            quên mật khẩu-->
            <div class="my-1 text-center">
              <b-link @click="showModal('modal-forgot-password')">
                Quên mật khẩu
              </b-link>
            </div>
          </b-form>
        </b-col>
      </b-col>
    </b-row>

    <b-modal
      id="modal-forgot-password"
      ref="modal-forgot-password"
      title="Hướng dẫn đổi mật khẩu"
      ok-title="OK"
      ok-only
      size="md"
      header-text-variant="primary"
    >
      <div class="d-flex gap-1">
        <b-form-input
          id="forgot-password-email"
          v-model="forgotPassword.email"
          placeholder="Điền email đăng nhập"
          type="email"
        />
        <b-button
          class="text-nowrap"
          variant="primary"
          size="sm"
          @click="handleForgotPassword"
        >
          Nhận mã
        </b-button>
      </div>
      <br>
      <small>Sau khi <strong class="text-primary">Nhận mã</strong> hãy vào mail để biết mã xác nhận</small>
      <hr>
      <b-form-group
        label="Mã xác nhận"
        label-for="forgot-password-code"
      >
        <b-input-group class="input-group-merge">
          <b-form-input
            id="forgot-password-code"
            v-model="forgotPassword.code"
            placeholder="Mã xác nhận"
            type="text"
            autocomplete="off"
          />
        </b-input-group>
      </b-form-group>
      <b-form-group
        label="Mật khẩu mới"
        label-for="forgot-password-new"
      >
        <b-input-group class="input-group-merge">
          <b-form-input
            id="forgot-password-new"
            v-model="forgotPassword.password"
            placeholder="Mật khẩu mới"
            type="password"
            autocomplete="off"
          />
        </b-input-group>
      </b-form-group>
      <b-form-group
        label="Nhập lại mật khẩu mới"
        label-for="forgot-password-repassword"
      >
        <b-input-group class="input-group-merge">
          <b-form-input
            id="forgot-password-repassword"
            v-model="forgotPassword.repassword"
            placeholder="Nhập lại mật khẩu mới"
            type="password"
            autocomplete="off"
          />
        </b-input-group>
      </b-form-group>
      <div class="text-right">
        <b-button
          class="text-nowrap"
          variant="primary"
          block
          @click="handleResetPassword"
        >
          Đổi mật khẩu
        </b-button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import {
  BAlert, BButton, BCardTitle, BCol, BForm, BImg, BRow, VBTooltip, BLink, BFormInput, BFormGroup, BInputGroup,
} from 'bootstrap-vue'
import useJwt from '@/auth/jwt/useJwt'

import { $themeConfig } from '@themeConfig'
import DynamicForm from '@core/dynamic-form/DynamicForm.vue'
import { reactive, ref } from '@vue/composition-api'
import store from '@/store'
import util from '@/global/util'

export default {
  directives: {
    'b-tooltip': VBTooltip,
  },
  components: {
    DynamicForm,
    BRow,
    BCol,
    BCardTitle,
    BFormInput,
    BImg,
    BForm,
    BButton,
    BAlert,
    BFormGroup,
    BInputGroup,
    BLink,
  },
  setup() {
    const form = ref({
      email: '',
      password: '',
    })

    const forgotPassword = reactive({
      code: ref(''),
      password: ref(''),
      repassword: ref(''),
      email: ref(''),
    })

    const {
      appName,
      loginBackground,
    } = $themeConfig.app

    return {
      form,
      forgotPassword,
      appName,
      loginBackground,
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  methods: {
    handleForgotPassword() {
      const { email } = this.forgotPassword
      const error = util.validValue('Email', email, 'required')
          || util.validValue('Email', email, 'email')
      if (error) {
        this.$toastr(error, 'danger')
        return
      }
      this.$call(store.dispatch('app/forgotPassword', { email })).then(() => {
        this.$toastr('Vui lòng kiểm tra Email của bạn')
      })
    },
    handleResetPassword() {
      if (!this.forgotPassword.password || !this.forgotPassword.email || !this.forgotPassword.repassword || !this.forgotPassword.code) {
        this.$toastr('Vui lòng nhập đủ thông tin cần thiết', 'danger')
        return
      }
      if (this.forgotPassword.password !== this.forgotPassword.repassword) {
        this.$toastr('Nhập lại mật khẩu không chính xác', 'danger')
        return
      }
      this.$call(store.dispatch('app/resetPassword', this.forgotPassword)).then(() => {
        this.$toastr('Đổi mật khẩu thành công, vui lòng đăng nhập bằng mật khẩu mới')
        this.hideModal('modal-forgot-password')
      })
    },
    login() {
      const errors = this.getErrorForm('login-form')
      if (errors.length) {
        this.$toastr('Vui lòng nhập đúng dữ liệu trước khi thao tác', 'danger')
        return
      }
      useJwt.login(this.form)
        .then(res => {
          const userData = res.data?.data?.user

          localStorage.setItem('userData', JSON.stringify(userData))
          store.commit('app/setUserData', userData)

          // if (this.isRememberMe) {
          useJwt.setToken(res.data.data.token)
          // }

          this.$router.replace(util.getHomeRouteForLoggedInUser(userData.role))
            .then(() => {
              this.$toastr(`Xin chào ${userData.fullname || userData.username}!`, 'success', { text: `Chúc ${userData.fullname || userData.username} làm việc hiệu quả` })
            })
        })
        .catch(err => {
          let error = err?.response?.data?.message
          error = error || err?.response?.data?.error?.message || err.message || err
          this.$toastr(error, 'danger')
        })
    },
  },
}
</script>

<style lang="scss">
@import 'src/@core/scss/vue/pages/page-auth.scss';
</style>
